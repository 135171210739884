@mixin select-icon {
    font-size: .82rem;
    position: relative;
    top: 1px;
}

.mat-form-field {
    label {
        padding-left: .5rem;
    }

    input {
        padding-left: .5rem;
    }

    //use button class to style a mat-select like a mat-stroked-button
    &.button {
        width: 120px;

        .mat-select {
            border: 1px solid $navy;
            border-radius: 0.2rem;
            padding: 5px 5px 6px 5px;
        }

        .mat-form-field-flex {
            background-color: transparent;
        }

        .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-label,
        mat-select-trigger {
            color: $navy;
            font-size: 14px;
            top: 24px;

            i {
                @include select-icon;
            }
        }
    }
}

//options dropdown
.mat-select-panel {
    min-width: auto !important;
    width: 100%;
    border-radius: 0 !important;
    position: relative;
    left: 14px;
    top: 23px;

    i {
        @include select-icon;
    }

    .mat-option {
        width: 100%;
        font-size: 0.8125rem !important; 
        height: 2em !important;
        padding: 0 5px;
    }
}


i + .mat-form-field {
    label {
        padding-left: 1.5rem;
    }

    input {
        padding-left: 1.5rem;
    }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: rgba($black, 0.87);
}

.mat-form-field.mat-focused {

    .mat-form-field-label {
        color: $green-medium !important;
    }

    .mat-form-field-ripple {
        background-color: $green-medium !important;
    }
}

.select-all {
    padding: 0 0 6px 5px !important;
    border-bottom: 1px solid #ccc !important;

    label {
        color: rgba(0,0,0,0.87);
        font-size: 13px;
    }
  }