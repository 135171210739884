/* top header nav css here*/

header {
    display: block;
    height: 3.75rem;
    width: 100%;
    position: fixed;
    padding: 0px;
    padding-left: 17.625rem;
    z-index: 999;
    background-color: $white;
    margin-bottom: 0px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;

    &:has(.branding) {
        height: 4.5rem;
        padding-left: 17.225rem;


        .logo {
            width: 16rem !important;
            height: auto !important;
        }
    }

    .header-container {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        .nav-logo {
            .logo {
                width: 14rem;
            }
            a {
                color: $white;
                height: 3.75rem;
                display: inline-block;
                line-height: 3.75rem;
                text-align: center;
                img {
                    width: 100%;
                }
            }
        }
        .nav-left {
            float: left;
        }
        .nav-right {
            position: relative;
            list-style: none;
            padding-left: 0;
            margin-bottom: 0px;
            float:right;
            margin-right: 0.625rem;
            > li {
                float: left;
                display: flex;
                align-items: center;
                height: 3.75rem;
                > a {
                    padding: 0 0.375rem;
                    color: $navy;
                    display: block;
                    text-decoration: none;
                    border-right: 1.2px solid $grey-light;
                    line-height: 1rem;
                    transition: all 0.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;
                    -moz-transition: all 0.2s ease-in-out;
                    -o-transition: all 0.2s ease-in-out;
                    -ms-transition: all 0.2s ease-in-out;
                    i {
                        color: $white;
                        border-radius: 50%;
                        border: 0;
                        color: $navy;
                        transition: all 0.3s ease-in-out;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        -ms-transition: all 0.3s ease-in-out;
                        &:focus {
                            text-decoration: none;
                            background-color: $green-medium;
                            border-color: $green-medium;
                            color: $white;
                        }
                    }
                    &.dropdown-toggle:after {
                        display: none;
                        border-radius: 0px;
                    }
                }
                &.menu-toggle-li {
                    display: none;
                    a {
                        &.sidenav-fold-toggler {
                            padding: 0 0.625rem;
                            border: none;
                            i {
                                background-color:$green-medium;
                                padding: 0.5rem;
                                color: $white;
                                border-radius: 0.125rem;
                                width: 1.975rem;
                                height: 1.975rem;
                                display: inline-block;
                                line-height: 1em;
                                transition: all 0.2s ease;
                                -webkit-transition: all 0.2s ease;
                                -moz-transition: all 0.2s ease;
                                -o-transition: all 0.2s ease;
                                -ms-transition: all 0.2s ease;
                            }
                        }
                    }
                }
                .fad {
                    &::before {
                        left: 0;
                        top: 0;
                        right: 0;
                    }
                }
            }
            
            .user-profile {
                .profile-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $grey;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                a {
                    border-right: none;
                    background-color: transparent;
                }
                .user-name {
                    padding: 0 0.625rem 0 1.25rem;
                    color: $black;
                }
                .dropdown-menu {
                    min-width: 15.625rem;
                    border: none;
                    border-radius:0.25rem;
                    top: 0.8125rem !important;
                    padding-top: 0;
                    margin: 0;
                    overflow: hidden;
                    border: 0.0625rem solid $grey;
                    li {
                        a {
                        line-height: 1.5;
                        min-height: auto;
                        padding: 0.625rem 1.25rem;
                        display: block;
                        color: $navy;
                        &:hover {
                            color: $green-medium;
                        }
                       i {
                        margin-right: 0.625rem;
                       }
                        }
                        &.user-name-device {
                            background-color: $navy;
                            display: flex;
                            align-items: center;
                            padding: 0.9375rem 1.25rem;
                            margin-bottom: 0.625rem;
                            // img {
                            //     margin-right: 0.9375rem;
                            //     border: 0.125rem solid $white;
                            //     width: 2.5rem;
                            // }
                            .user-info {
                                margin-left: 1rem;
                                a {
                                    color: $white;
                                    padding: 0;
                                    font-size: $font16;
                                }
                                .user-role {
                                    color:rgba(244, 246, 249, 0.57);
                                    font-size: $font13;
                                    line-height:1rem;
                                }
                            }
                            
                        }
                    }
                }
            }

            /*increase decrease font style */
            .setting {
                .font-size-btns {
                    top: 1.1rem !important;
                    padding: 0.5rem;
                    li {
                        display: flex;
                        justify-content: center;
                        .btn {
                            width: 2.275rem;
                            height: 2.275rem;
                            min-width: 0;
                            padding: 0;
                            background-color: $navy;
                            color: $white;
                            display: flex;
                            text-align: center;
                            font-size: $font14;
                            line-height: 0.875rem;
                            border: 0;
                            border-radius: 50%;
                            margin: 0 0.3125rem !important;
                            align-items: center;
                            justify-content: center;
                            white-space: nowrap;

                            i {
                                &:first-child {
                                    width: 1.6em;
                                }
                                &:nth-child(2) {
                                    width: 0.7em;
                                }
                            }
                        }
                    }
                }
            }
            /*increase decrease font style end*/
        }
    }
}

@media only screen and (max-width: 1024px) {
    header {
        padding-left: 0px !important;
        .header-container {
            .nav-logo {
                padding: 0 0.625rem;
                .logo {
                    width: 12rem;
                }
            }
            .nav-right {
                margin-right: 0;
                .user-profile {
                    .dropdown-toggle {
                        padding: 0 0.625rem;
                    }
                    span.user-name {
                        display: none;
                    }
                }
                .hide-on-device {
                    display: none;
                }
                li {
                    &.menu-toggle-li {
                        display: flex;
                    }
                }
            }
        }
    }
    .side-nav-folded {
        .sidenav-fold-toggler {
            .icon-menu {
                border-radius: 50% !important;
                &::before {
                    content: "\e928"
                }
            }
        }
    }
}

