/*modal related css here*/

/* modal animation */
@keyframes modal-b-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes modal-fade {
    from {
        top: -6.25rem;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

.modal-backdrop {
    opacity: 0.5;
    animation: ease-in-out .3s modal-b-fade;
    transition: all .3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.50);
}

.modal {
    top: -6.25rem;
    animation: ease-in-out .3s modal-fade;
    transition: all .3s ease-in-out;

    &.show {
        top: 0;
        animation: ease-in-out .3s modal-fade;
        transition: all .3s ease-in-out;
    }
    .modal-header {
        padding: 0.5rem 1rem;
        border-color: $grey;
        i {
            color: $green-medium;
            margin-right: 0.3125rem;
        }
    }
    .modal-body {
        padding-bottom: 0 !important;
        .captacha-field {
            width: 14rem;
            margin: 1rem auto;
            .form-control {
                border: 1px solid $grey;
                border-radius: 4px;
                padding: 10px;
            }
        }
    }
    .modal-footer {
        border-top: 1px solid $grey;
        .btn {
            min-width: 4rem;
            padding: 0.25rem;
            font-size: $font14;
        }
        /*just add following class with "modal footer class for remove top border of modal footer"*/
        &.no-top-border {
            border-top:0!important;
            justify-content: center !important;
        }
    }
}

.confirm-modal {
    .modal-dialog {
        width: 350px;
    }
}

/* media css for modal */

@media (max-width: 768px) {
    .modal-dialog {
        width: auto;

        .modal-content {
            height: 100%;
        }
    }
}


.session-popup {
    @media (min-width: 544px) {
        .modal-dialog {
            max-width: 430px;
        }
        }
        .session-body {
            .alert-img {
                width: 50px;
                height: 50px;
                display: inline-block;
            }
            
            p {
                span {
                    font-size:1.2rem;
                }
            }
        }
}

.zips-modal {
        .modal-dialog {
            // max-height: calc(100% - 3.5rem);
           
            display: flex;
            h1 {
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
            h2 {
                font-size: 0.875rem;
            }
            @media (min-width: 544px) {
                max-width: 400px;
                .modal-body {
                    overflow-y: auto;
                    max-height: 400px;
                }
            }
            
            .modal-body {
                ul {
                   
                    li {
                    width: 30%;
                    margin: 5px;
                    float: left;
                    background: #f7f7f7;
                    margin-bottom: 4px;
                    border-radius: 4px;
                    }
                }
            }
        }
       
}