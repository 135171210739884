.mat-checkbox {

    &.mat-checkbox-checked {
        .mat-checkbox-background {
            background: rgba($success, 1);
        }
    }

    &:not(.mat-checkbox-disabled) {
        &.mat-accent {
            .mat-checkbox-ripple {
                .mat-ripple-element {
                    background: rgba($success, 1);
                }
            }
        }
    }

    &.mat-checkbox-indeterminate {
        .mat-checkbox-background {
            background: rgba($grey-dark, 1);
        }
    }

    label {
        position: relative;
        top: 4px;
        margin-bottom: 0;
    }

    &.mat-checkbox-disabled {
        cursor: not-allowed;

        .mat-checkbox-inner-container {
            background-color: #ccc;
        }
    }
}

.mat-primary {
    .mat-pseudo-checkbox-checked {
        background: rgba($success, 1);
    }
}

td, th {
    .mat-checkbox {
        position: relative;
        top: -5px;
    }
}