
@font-face {
    font-family: 'open_sanslight';
    src: url('/assets/fonts/open-sans/OpenSans-Light-webfont.eot');
    src: url('/assets/fonts/open-sans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/open-sans/OpenSans-Light-webfont.woff') format('woff'),
         url('/assets/fonts/open-sans/OpenSans-Light-webfont.ttf') format('truetype'),
         url('/assets/fonts/open-sans/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('/assets/fonts/open-sans/OpenSans-Regular-webfont.eot');
    src: url('/assets/fonts/open-sans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/open-sans/OpenSans-Regular-webfont.woff') format('woff'),
         url('/assets/fonts/open-sans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/open-sans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('/assets/fonts/open-sans/OpenSans-Semibold-webfont.eot');
    src: url('/assets/fonts/open-sans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/open-sans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('/assets/fonts/open-sans/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('/assets/fonts/open-sans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}




// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$grid-gutter-width: 30px;
$pad: floor($grid-gutter-width/2);

// breakpoints
$grid-breakpoints: ( xs: 0, sm: 544px, md: 768px, lg: 992px, xl: 1200px);

// same as default
$screen-xs: map-get($grid-breakpoints, 'xs');
$screen-sm: map-get($grid-breakpoints, 'sm');
$screen-md: map-get($grid-breakpoints, 'md');
$screen-lg: map-get($grid-breakpoints, 'lg');
$screen-xl: map-get($grid-breakpoints, 'xl');

// colors
$theme-colors: ( 
    primary:    #469200, 
    dark:       #223a5c,
    secondary:  #e8e8e8, // bootstrap default
    success:    #3cba54, // bootstrap default
    info:       #50a9e8, // bootstrap default
    warning:    #f4c20d, // bootstrap default
    danger:     #dd3237, // bootstrap default
    light:      #d7d7d7, // bootstrap default
);

$brand-primary: map-get($theme-colors, primary);
$brand-secondary: map-get($theme-colors, secondary);
$brand-success: map-get($theme-colors, success);
$brand-info: map-get($theme-colors, info);
$brand-warning: map-get($theme-colors, warning);
$brand-danger: map-get($theme-colors, danger);
$brand-light: map-get($theme-colors, light);
$brand-dark: map-get($theme-colors, dark);

$green:      #469200; //old
$green-dark: #058744;
$green-medium: #3c9c50;
$green-light: #b1cf52;

$navy:       #223a5c;
$white:      #ffffff;
$black:      #212529;
$grey:       #d7d7d7;
$grey-light: #f4f6f9;
$grey-dark:  #8f8f8f;
$success:    #5cb85c;
$error:      #dd3237;
$warning:    #cca300;

/* font-size */
$font12:0.75rem;
$font13:0.8125rem;
$font14:0.875rem;
$font15:0.9375rem;
$font16:1rem;
$font17:1.0625rem;
$font18:1.125rem;
$font20:1.25rem;
$font22:1.375rem;
$font24:1.5rem;
$font30:1.875rem;
$font34:2.125rem;

$font-primary: 'open_sansregular'; // body font
$site-secondary-font: 'open-sans', sans-serif;

$site-font-features: sans;
$button-font-family: $font-primary;

$button-font-weight: 400;
$regular: 400;
$semi-bold: 500;
$bold: 700;

$sizes: () !default;
$sizes: map-merge(
  (
   5: 5%,
  10: 10%,
  15: 15%,
  20: 20%
  ),
  $sizes
);

@mixin content-width() {
    width: 60em;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: 768px) {
        padding: 0 1em;
    }
}

@mixin background-center() {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
