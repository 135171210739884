/* ON/OFF Switch */
.toggle-switch-btn {
  .toggle-group {
    position: relative;
    top: 0px;

    input[type=checkbox] {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-inner {
      margin-left: 0;
    }

    input[type=checkbox]:checked~.onoffswitch .onoffswitch-label .onoffswitch-switch {
      right: 6px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }

    input[type=checkbox]:focus~.onoffswitch {
      outline: thin dotted #333;
      outline: 0;
    }

    label {
      position: absolute;
      cursor: pointer;
      padding-left: 100px;
      display: inline-block;
      text-align: left;
      line-height: 32px;
      z-index: 1;
      height: 32px;
      font-weight: 200;
      margin-bottom: 0;
    }

    /* ==== Accessibility ===== */
    .aural {
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      width: 1px;

      &:focus {
        clip: rect(0, 0, 0, 0);
        font-size: 1em;
        height: auto;
        outline: thin dotted;
        position: static !important;
        width: auto;
        overflow: visible;
      }
    }

    .onoffswitch {
      position: relative;
      width: 100px;
      display: inline-block;

      .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid hsl(0, 0%, 90%);
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        margin: 0;

        .onoffswitch-inner {
          width: 200%;
          margin-left: -100%;
          -webkit-transition: margin 0.15s ease-in-out;
          -o-transition: margin 0.15s ease-in-out;
          -moz-transition: margin 0.15s ease-in-out;
          transition: margin 0.15s ease-in-out;

          &:before,
          &:after {
            float: left;
            width: 50%;
            height: 32px;
            padding: 0;
            line-height: 32px;
            font-size: 0.875rem;
            color: hsl(0, 0%, 100%);
            font-weight: normal;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          &:before {
            content: "Show All";
            padding-left: 10px;
            background-color: #5cb85c;
            color: hsl(0, 0%, 100%);
          }

          &:after {
            content: "Attached";
            padding-right: 10px;
            background-color: #e2dfdf;
            color: #929191;
            text-align: right;
          }
        }

        .onoffswitch-switch {
          width: 22px;
          height: 22px;
          margin: 0;
          background: hsl(0, 0%, 100%);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          bottom: 0;
          right: 74px;
          -webkit-transition: right 0.15s ease-in-out;
          -o-transition: right 0.15s ease-in-out;
          -moz-transition: right 0.15s ease-in-out;
          transition: right 0.15s ease-in-out;
        }
      }
    }
  }

  &.manage-employee-switch {
    .toggle-group {
      top: 0;
    }
  }

  &.document-type-toggle-switch-btn {
    .toggle-group {
      .onoffswitch {
        .onoffswitch-label {
          .onoffswitch-inner {
            &:after {
              background-color: #5cb85c;
              color: #fff;
            }

            &:before {
              background-color: #e2dfdf;
              color: #000;
            }
          }
        }
      }
    }
  }

  &.ga-management-toggle-switch-btn {
    .toggle-group {
      top: 8px;

      label {
        padding-left: 175px;
      }

      .onoffswitch {
        width: 175px;

        .onoffswitch-label {
          .onoffswitch-inner {
            &:before {
              content: "General Agency";
            }

            &:after {
              content: "Independent Agency";
              background-color: #5cb85c;
              color: hsl(0, 0%, 100%);
            }
          }

          .onoffswitch-switch {
            right: 148px;
          }
        }
      }

      input[disabled] {
        &+ label {
          pointer-events: none;
        }

        &~ .onoffswitch .onoffswitch-label .onoffswitch-inner:before {
          background-color: #bbb;
          cursor: not-allowed;
        }

        &~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
          cursor: not-allowed;
        }
      }

    }
  }
}
  