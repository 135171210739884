.search-row {
    margin-bottom: 1.5rem;

    i.fa-search {
        position: absolute;
        top: 17px;
        font-size: 1.1rem;
        z-index: 2;
    }

    .col-inactive { 
        max-width: 180px !important;
        margin-top: 1rem;
    }

    .col-btns {
        max-width: 300px !important;
        margin-top: 0.5rem;

        .toggle-group {
            margin-top: -0.5rem;
        }
    }
}