/*left sidebar nav css here*/
.side-nav {

  &:has(.branding) {
    background-color: #3c7d9c;
  }

  width: 16.225rem;
  background-color: $grey-dark;
  z-index: 1000;
  top:0;
  bottom: 0px;
  left: 0;
  position: fixed;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  .side-nav-inner {
    position: relative;
    height: 100%;
    .side-nav-menu {

      &.branding {
        li {
          &.dropdown {
            > .active-link {
              background: linear-gradient(90deg, $green-dark 0%, $green-medium 50%, $green-light 100%);
            }
          }
        }
      }

      position: relative;
      list-style: none;
      margin: 0;
      padding-left: 0;
      overflow: auto;
      height: calc(100vh - 2.2rem);
      overflow: hidden;
      overflow-y: auto;
      li {
        position: relative;
        display: block;
        cursor: pointer;
        &.dropdown {
          >.dropdown-toggle {

            i {
              font-size: 1.4rem;
              width: 1em;
              line-height: .75em;
            }

            .fal {
              display: block;
            }
          
            .fas:not(.fa-chevron-down) {
              display: none;
            }
          
            &:hover {
              .fal {
                display: none;
              }
          
              .fas {
                display: block;
              }
            }

            &.active-link {
              color: $white;
              background-color: $green-medium;

              .fal {
                display: none;
              }
          
              .fas {
                display: block;
              }
            }
          }
          &.open.active-link {
           >.dropdown-toggle {
            color: $white;
            background-color: $green-medium;
           }
           .dropdown-menu {
             &.sub-down {
              .dropdown-toggle {
                &.active-link {
                  color: $white;
                  font-weight: bold;
                }
              }
              
             }
           }
          }
          .arrow {
            position: absolute;
            right: 1.875rem;
            top: 0.8rem;
            line-height: 1;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            i {
              font-size: 1rem;
            }
          }
          ul {
            &.dropdown-menu {
              position: relative;
              width: 100%;
              box-shadow: none;
              border: 0px;
              border-radius: 0;
              padding-left: 1.5rem;
              padding-top: 0px;
              background-color: transparent;
              float: none;

              li {
                a {
                  padding: 0.6125rem 0 0.6125rem 0.5375rem;
                  
                  &:hover,
                  &:focus {
                    background-color: transparent;
                    color: $white;
                  }
                  i {
                    font-size: 1.4rem !important;
                  }
                }
                &.active {
                  a {
                    color: $white;
                  }
                }
              }
            }
          }
        }
        &.dropdown.open {
          a {
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            .arrow {
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
            }
          }
          .dropdown-menu {
            display: block;
            .arrow {
              line-height: 1.5625rem;
            }
            a.active-link {
              color: $green-medium;
              background: transparent;
            }
          }
          > a {
            color: $white;
            background: $green-medium;
          }
        }
        a {
          color: $white;
          position: relative;
          display: block;
          padding: 0.7125rem 0 0.7125rem .9375rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          white-space: nowrap;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          i:not(.fal) {
            font-size: $font20;
          }
          .title {
            margin-left: 0.9375rem;
            line-height: 1rem;
            font-size: 1rem;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            color: $white;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
          }
          &.dropdown-toggle:after {
            display: none;
            border-radius: 0px;
          }
          &:hover,
          &:focus {
            color: $white;
          }
        }

        &:first-child {
          a {
            padding-top: 1.2rem;
          }
        }
      }
    }

    .fold-menu-on-desktop {

      &.branding {
        background: linear-gradient(90deg, $green-dark 0%, $green-medium 50%, $green-light 100%);
      }

      display: flex;
      justify-content: flex-end;
      background-color: $green-medium;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1000;
      a {
        padding: 0.4rem 0.4375rem 0.2rem 0.4375rem;
        color: $white;
      }
    }
  }
}

.side-nav-folded {
  .side-nav {
    width: 4.0625rem;
    .side-nav-inner {
      .side-nav-menu {
        > li {
          > a {
            .title {
              display: none;
            }
          }
        }
        li {
          &.dropdown {
            .arrow {
              opacity: 0;
            }
          }

          &.dropdown.open {
            ul {
              &.dropdown-menu {
                display: none !important;
              }
            }
          }
        }
      }
    }
    .fold-menu-on-desktop {
      a {
        padding: 0.4rem 0.4375rem;

        .fa-arrow-alt-left:before {
          content: "\f356";
       }
      }
    }
  }
}

.overlay {
  display: none;
}

/*media width maxwidth 1024px css for sidebar nav*/

@media only screen and (max-width: 1024px) {
  .side-nav {
    right: -16.25rem;
    left: auto;
    top: 3.75rem;
  }
  .side-nav-folded {
    .page-container {
      padding-left: 0 !important;
    }
    .side-nav {
      right: 0px;
      width: 15.625rem;
      .side-nav-inner {
        .side-nav-menu {
          > li {
            > a {
              .title {
                display: block;
              }
            }
          }
          li {
            &.dropdown {
              .arrow {
                opacity: 1;
              }
            }
  
            &.dropdown.open {
              ul {
                &.dropdown-menu {
                   display: block !important;
                }
              }
            }
          }
        }
      }
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10;
      display: block;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .side-nav .side-nav-inner .fold-menu-on-desktop {
    display: none;
  }
}