.material-dialog {

  h1[mat-dialog-title] {
    i {
      font-size: 1.6rem;
      margin-right: 0.5rem;
      color: #f4c20d;
    }
  }

  h2[mat-dialog-title] {
    font-size: 1.2em;
    line-height: 1em;
    margin-bottom: 0;
  }

  h3[mat-dialog-title] {
    font-size: 1em;
    color: $navy;
  }

  [mat-dialog-content] {
    margin-bottom: 2rem;
    line-height: 1.7;

    .has-float-label {
        margin-top: 1.1em;
    }

    [mat-table] .form-control {
        width: 100px;
    }
  }

  mat-dialog-actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 0;

      &.employee-hub-dialog {
        justify-content: end;
      }
  }
}
