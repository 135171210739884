/* You can import scss styles to this file only, Do not write any css/scss in this file */

@import "assets/scss/variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";

/* components scss*/
@import "assets/scss/components/header";
@import "assets/scss/components/side-nav";
@import "assets/scss/components/buttons";
@import "assets/scss/components/button-group";
@import "assets/scss/components/forms";
@import "assets/scss/components/modal";
@import "assets/scss/components/panel";
@import "assets/scss/components/progress";
@import "assets/scss/components/tables";
@import "assets/scss/components/tabs";
@import "assets/scss/components/alert";
@import "assets/scss/components/images";
@import "assets/scss/components/pagination";
@import "assets/scss/components/tooltip";
@import "assets/scss/components/icons-font";
@import "assets/scss/components/mat-stepper";
@import "assets/scss/components/mat-button";
@import "assets/scss/components/mat-dialog";
@import "assets/scss/components/mat-form-field";
@import "assets/scss/components/mat-checkbox";
@import "assets/scss/components/search-row";

/* theme css includes only color scheme for all global components */
@import "assets/scss/theme";
@import "assets/scss/global";

