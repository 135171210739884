.mat-step-header {
    .mat-step-icon {
        background-color: rgba(0,0,0,.4);
    }

    .mat-step-icon-state-edit {
        background-color: $navy;
     }

    .mat-step-icon-state-done {
        background-color: $success;
    }
}