/* tabs related css here*/

.nav-tabs {

	&.nav-sm {
		.nav-item .nav-link {
			padding: 0.5rem 0;
			padding-right: 0.3rem;
		}
	}

	.nav-link {
		color: $black;

		&:focus {
			border-bottom-color: transparent !important;
		}

		&.active {
			color: $green-medium !important;
		}
	}
}

ngb-tabset {
	.tab-content {
		padding: 1rem 0 0;
	}
	.nav-tabs {
		border-bottom: 0.0625rem solid #f1f2f3;
		.nav-item {
			margin-bottom: 0;
			.nav-link {
				border: 0;
				position: relative;
				//min-width: 6.25rem;
				text-align: center;
				color: $black;
				margin-right: 1rem;
				margin-bottom: 10px;
				line-height: 2;
				.tab-anchor {
					padding: 0 !important;
				}
				&.active {
					border-bottom: 2px solid  $green-medium !important;
					color:  $green-medium;
				}
			
			}
		}
	}
}


@media (max-width: 441px) {
	ngb-tabset {
		.nav-tabs  {
			justify-content: space-between !important;
			.nav-item {
				flex-basis: 48%;
				.nav-link {
					border-radius:  0.3125rem !important;
					padding: 0.3125rem 0.625rem;
					margin-bottom:  0.3125rem;
					line-height: 1.2rem;
					border: 1px solid $navy;
					&.active, &:focus {
						background-color: $green-medium;
						color: $white !important;
						border-color: $green-medium !important;
						&:after, &:before {
							display: none;
						}
						
					}
				}
			}
		}
	} 
	.back-listing {
        margin-bottom:10px;
    }
}


.update-basic-details {
    .form-container {
        padding: 0 !important;
        .card {
            padding: 0 !important;
        }
    }
}