/*buttons related all css here*/

.btn {
    // font-size: $font16;
    // padding: 0.375rem 0.75rem;
    // min-width: 5.25rem;
    font-size: 0.875rem;
    padding: 0.3125rem 0.4375rem;;
    margin: 0;
    min-width: 4.6875rem;
    cursor: pointer !important;

    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);

    &.btn-primary {
        color: $white;
        background-color: $green-medium !important;
        border-color: $green-medium !important;
    }
    &.btn-secondary {
        box-shadow: none;
    }
    &.btn-outline {
        font-size: $font14;
        padding: 0.3125rem 0.4375rem;
        margin: 0;
        min-width: 4.6875rem;
    }
    &.btn-outline+.btn-outline {
        margin-right: 0 !important;
        margin-left: 0.625rem;
    }
    &.btn-search {
        min-width: 1rem;
    }
    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
}

a {
    &.btn-primary {
        color: $white !important;
    }
}

.btn+.btn {
    margin-right: 0 !important;
    margin-left: 0.625rem;
}

.btn-group {
    &.btn-group-toggle {
        .btn {
            margin: 0;
            box-shadow: none;
        }
    }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
}


/*swicth button*/

.switch {
    position: relative;
    display: inline-block;
    width: 3.75rem;
    height: 2.1875rem;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    input:checked+.slider {
        background-color: $navy;
    }
    input:focus+.slider {
        box-shadow: none;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(1.625rem);
        -ms-transform: translateX(1.625rem);
        transform: translateX(1.625rem);
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 1.625rem;
            width: 1.625rem;
            left: 0.25rem;
            bottom: 0.25rem;
            background-color: $white;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            border-radius: 2.125rem;
            &:before {
                border-radius: 50%;
            }
        }
    }
}

// media queries csss for buttons
@media only screen and (max-width:992px) {
    .btn {
        font-size: $font16;
        padding: 0.375rem 0.9375rem;
        margin: 0;
    }
}

@media only screen and (max-width:441px) {
    .btns-center-on-device {
        text-align: center !important;
    }
}