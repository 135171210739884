/* global css for layout here*/


html {
    overflow-x: inherit;
    font-size: 16px;
}

html,
body {
    height: 100%;
    font-family: $font-primary;
}

body {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    background-color: $grey-light;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.8;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
}


/*css general setting*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
}

h1 {
    font-size: 1.25rem;
    /*20px*/
}

h2 {
    font-size:1.125rem;
    /*18px*/
}

h3 {
    font-size: 1rem;
    /*16px*/
}

h4 {
    font-size: 0.875rem;
    /*14px*/
}

h5 {
    font-size: 0.75rem;
     /*12px*/
}

.font16 {
    font-size: 1rem;
}

p,
div,
label,
a {
    font-size: $font14;
    /*16px*/
}

a:hover {
    text-decoration: none;
    color: $navy;
    cursor: pointer;
}

a:focus,
input,
.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $grey !important;
}

ul,
ol {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

ul li a,
ol li a {
    list-style: none;
    font-size: $font14;
    /*14px*/
}
b, strong {
    font-family: 'open_sanssemibold';
    font-weight: 300;
}



table tr th {
    font-size: $font14;
    /*14px*/
    line-height: 1rem;
    font-family: 'open_sanssemibold';
    font-weight: 300;
}

table tr td {
    font-size: $font14;
    /*14px*/
    line-height: 1rem;
}

input {
    font-family: $font-primary !important;
}

.form-control,
input {
    font-size: $font14;
    color: $black;
    font-weight: 400;
    font-family: $font-primary !important;
}


:focus {
    outline: 0 !important;
}


/* removed number spinner from input type number field for firefox browser*/

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.line-height-20 {
    line-height: 1.2rem;
}

.line-height-15 {
    line-height: 0.9375rem;
}


/*common-colors*/

.orange {
    color: map-get($theme-colors, orange);
}

.danger {
    color: map-get($theme-colors, danger);
}

.grey {
    color: map-get($theme-colors, light) !important;
}

.navy {
    color: map-get($theme-colors, dark);
}

.badge {
    padding: .4rem .4rem .4rem .4rem;
    font-size: $font13;
    font-weight: normal;
    border-radius: 2px;
    i {
        font-size: 0.5rem;
        margin-left: .1rem;
        cursor: pointer;
    }
}


/*pointers*/

.not-allowed {
    cursor: not-allowed !important;
}

.cursor {
    cursor: pointer !important;
}

.min-width-0 {
    min-width: 0;
}

.tooltip {
    z-index: 1049;
}
.flex-1 {
    flex: 1;
}
/* css for main layout*/

.layout {
    .page-container {
        min-height: 100vh;
        padding-left: 15.625rem;
        transition: all 0.2s ease;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        .main-content {
            padding: calc(3.125rem + 2.1875rem) 0.9375rem 0.9375rem 0.9375rem;
            min-height: calc(100vh - 0px);
            width: 100%;
            flex-grow: 1;
            position: relative;
            footer {
                position: sticky;
                bottom: -1px;
                left: 0;
                border-top: 1px solid $grey;
                width: 100%;
                z-index: 3;
                background-color: $white;
                text-align: right;
                padding: 1.5rem 1.5rem 2.5rem 4.3rem;

                &#page-footer {
                    position: fixed;
                    margin-left: 280px;
                    padding: 0.2rem 0;
                    border-top: 0;
                    background-color: #f4f6f9;
                }
            }
        }
    }
    .container {
        &.form-container {
            max-width: 1240px;
            /*bootstrap card css override here*/
            .card {
                border: none;
                padding: 0 3.125rem;
                .card-header {
                    background-color: transparent;
                    border: none;
                    border-left: 0.1875rem solid $green-medium;
                    border-radius: 0;
                }
            }
        }
    }
    &.side-nav-folded {
        header {
            padding-left: 6rem;
        }
        .page-container {
            padding-left: 4.0625rem;
        }
    }
}


/*bootstrap card css override for fluid container here*/

.card {
    border: none;
    padding: 0;
    position: relative;

    .card-header {
        background-color: transparent;
        border: none;
        border-left: 0.1875rem solid $green-medium;
        border-radius: 0;
        display: flex;
        align-items: center;
        h1 {
            flex: 1;
        }
    }
    .card-body {
        margin-bottom: .5rem;
        padding-bottom: .75rem;
        padding-left: 1.60rem;
        padding-top: 1.5rem;
        @media (max-width: 441px) {
            &.broker-card-body {
                padding-top: 3rem;
            }
        }
    }
    .triangle-wrap {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 75px;
        .triangle-topleft {
            left: -21px;
            background-color: $danger;
            transform: rotate(-45deg);
            color: $white;
            text-align: center;
            font-size: 12px;
            padding: 0 15px;
            position: relative;
            top: 18px;
            @media (max-width: 1024px) {
                top: 15px;
                left: -20px;
                font-size: 10px
            }
            @media (max-width: 441px) {
                top: 15px;
                left: -19px;
            }
        }
    }
          &.deactiavted-card {
            .nav-item {
                .nav-link {
                        position: relative;
                        z-index: 1;
                }
            }
          }
}

/*profile photo upload css start*/

.profile-content {
    .profile-photo {
        width: 9.375rem;
        height: 9.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        position: relative;
        overflow: hidden;
        padding-bottom: 1.6875rem;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .upload-btn-wrapper {
            position: absolute;
            overflow: hidden;
            display: inline-block;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.76);
            width: 100%;
            left: 0;
            .btn {
                background-color: transparent;
                padding: 0.1875rem 0.3125rem;
                float: right;
                min-width: 0;
                line-height: 1rem;
                i {
                    color: $white;
                    font-size: $font18;
                }
            }
            input[type="file"] {
                position: absolute;
                left: 0;
                bottom: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }
    .broker-col {
        div {
            flex-basis: 100%;
            i {
                font-size: $font18;
            }
        }
        div+div {
            flex-basis: 3.75rem;
        }
    }
    @media (max-width: 575.98px) {
        .user-profile-photo {
            margin: 0 auto;
        }
    }
}

.img-upload-note {
    font-size: $font13;
    line-height: 1rem;
}


/*profile photo upload css end*/


/*password error css here*/

.password-error {
    ol {
        list-style: disc;
        li {
            font-size: $font13;
        }
    }
    &.password-strength {
        ol {
            list-style: none;
            margin-top: 1rem;
            li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                color: $navy;
                font-size: 13px;
                i {
                    margin-right: 10px;
                    font-size: 16px;
                    padding: 0 5px;
                    color: $danger;
                }
                &.success-check {
                    .fa-circle:before {
                        content: "\f00c";
                        color: $success;
                    }
                }
            }
        }
    }
}


/* hide header and sidebar from login page and other css*/



/*login and forgot page css here*/

.form-signin {
    background-color: $white;
    width: 100%;
    width: 30rem;
    padding: 2rem;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.18), 1px 1px 4px 0 rgba(0, 0, 0, 0.24);
    .logo {
        width: 15.625rem;
        margin: auto;
        margin-bottom: 3rem;
        margin-top: 1rem;
        img {
            width: 100%;
        }
    }
    .unable-login-guidelines {
        ol {
            list-style: decimal;
            padding-left: 2rem;
            li {
                font-size: $font13;
            }
        }
    }
}

.login-page-body {
    background-color: $grey-light;
    // app-header,
    // app-sidebar {
    //   display: none;
    // }
    .page-container {
        padding: 0;
        .main-content {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            app-signin,
            app-forgot-password {
                display: flex;
                width: 100%;
            }
        }
    }

    &.set-security-question {
        .form-signin {
            width: 30rem;
            &.two-factor-authentication {
                width: 40rem;
                @media screen and (max-width:1024px) {
                    h2 {
                        margin-bottom: 1.5rem !important;
                    }
                    .skip-btn {
                        margin-top:0.5rem !important
                    }
                }

            }
        }
    }
}



/* end login css*/
.guide-page-body {
    background-color: $grey-light;
    app-header,
    app-sidebar {
        display: none;
    }
    .page-container {
        padding: 2rem !important;
        .main-content {
            padding: 0;
            .container {
                background-color: #fff;
                padding: 1rem;
            }
        }
    }
}

/*page not found css here*/

.page-not-found {
    background-color: $grey-light;
    app-header,
    app-sidebar {
        display: none;
    }
    .page-container {
        padding-left: 0;
        .main-content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}


/*page not found css end here*/


/*no-access-right css here*/

.no-access-right {
    background-color: $grey-light;
    header {
        padding-left: 15px;
    }
    h1 {
        font-size: 2.25rem;
    }
    app-sidebar {
        display: none;
    }
    .page-container {
        padding-left: 0;
        .main-content {
            padding-left: 0;
            padding-right: 0;
            img.no-access {
                width: 140px;
                margin: auto;
            }
            h2 {
                font-size: 2rem;
                text-align: center;
                color: #000;
                margin: 1rem 0 3rem;
                font-weight: 700;
            }
            h3 {
                font-size: 1.2rem;
                text-align: center;
                color: #636060;
                margin: 3rem 0;
                line-height: 31px;
            }
        }
    }
}


/*no-access-right css here*/


/* media css for main layout*/

@media only screen and (max-width: 1024px) {
    .layout {
        .page-container {
            padding-left: 0;
            .main-content {
                padding-left: 0;
                padding-right: 0;
                footer {
                    padding: 1rem 1.25rem;
                }
                .container {
                    &.form-container {
                        .card {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}


/* scroll to top css here*/

.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transition: all .2s ease-in-out;
    background-color: rgba(57, 80, 112, 0.8);
    width: 40px;
    height: 40px;
    padding: 9px 12px;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    z-index: 1049;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    i {
        color: $white;
        position: relative;
        font-size: $font18;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

.show-scrollTop {
    opacity: 1;
    transition: all .2s ease-in-out;
}

.sub-title {
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: bold;
}


/* font size decrease for small device max-width 441px*/

@media only screen and (max-width: 441px) {
    html {
        font-size: 14px;
    }
    h4 {
        font-size: 1rem;
    }
    h3 {
        font-size: 1.25rem;
    }
    .form-signin {
        max-width: 25rem;
    }
    .spinner {
        display: flex;
        align-items: center;
        .lds-roller {
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
    }
}

#basicInfo,
#underwriterTab,
#underwriterTab1,
#portfolios,
#carrierForms,
#updateProfile,
#changePassword,
#activityLogs,
#ga-management,
#brokers,
#productDocumentTab,
#productBenefitTab,
#productAttributeTab,
#productFormTab,
#serviceAreas,
#serviceAreaProduct,
#censuses,
#productCensusTab,
#securityQuestions,
#productBasicTab,
#productAgeBandedTab,
#productCoverageTierTab,
#carrierPortfilioDocumentTab,
#carrierPortfolioBasicInfo,
#carrierPortfilioFormTab,
#carrierPortfilioCompositeTab,
#carrierPortfilioCensusTab,
#composite,
#contactPerson,
#securitySettings,
#functionalityRestriction,
#agencySubscription,
#addProgram {
    padding: 0;
    .tab-anchor {
        @media screen and (max-width:768px) {
        padding: 0.5rem 0.65rem !important;
        }
    }
}

.edit-angency-common-card {
    .card {
        padding: 0;
    }
}


ngb-typeahead-window {
    max-height: 300px;
    overflow-y: auto;

    button {
        font-size: 0.800rem;
    }
    @media screen and (max-width:441px) {
        width: 100%;
    }
}

/*scroll hover instruction css for view service area on products page*/

.screen-overlay {
    display: none;
}
.products-page-body {
    @media screen and (max-width:1024px) {
       .screen-overlay {
           position: absolute;
           width: 100%;
           height: 100%;
           top:0;
           right: 0;
           bottom: 0;
           left: 0;
           background-color: rgba(0, 0, 0, 0.82);
           color: $white;
           font-size: 2rem;
           display: flex !important;
           align-items: center;
           justify-content: center;
           z-index: 1050;
           padding: 10px;
           font-size: 1rem;
           p {
            font-size: 1.5rem;
            margin-bottom: 5rem;
           }
           img {
               position: absolute;
               top: 52px;
               right: 0;
               bottom: 0;
               left: 0;
              margin: auto;
              width: 50px;
           }
       }
    }
}

.breadcrumbs {
    position: relative;
    top:-10px;
    a {
        color: $navy;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .b-arrow {
        display: inline-block;
        margin: 0 7px;
        font-size: 1.5rem;
        line-height: 15px;
        position: relative;
        top: 1px;
    }

}


.upload-excel {
    .file-area {
        position: relative;
        display: inline-block;
        input[type=file] {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .file-name {
          color: #395070;
          font-weight: 600;
        }

        .file-dummy {
          width: 9.375rem;
          height: 9.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          border: 1px solid #ecebeb;
          margin: 0 0 5px;
          border-radius: 2px;
          i {
            font-size: 4rem;
            color: #ccc;
          }
          .default {
            text-align: center;
          }
          .success {
            display: none;
            text-align: center;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }

        input[type=file].uploaded-file + .file-dummy {
          .success {
            display: inline-block;
          }
          .default {
            display: none;
          }
        }
      }

    }

    .secure-ques{
        padding: 0;
        .footer-content {
            padding:2rem;
            padding-top: 0;
            border-top: 3px solid #dfe0e0;
            margin-top: 30px;
            padding-bottom: 0;
        }
        .footer-content2{
            padding:2rem;

        }
        .top-content {
            padding:2rem;
            padding-bottom: 0;
        }
        .form-group {
            position: relative;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            .plain-select {
                width: 100%;
                flex-basis: 76%;
            }
            .form-control {
                flex-basis: 76%;
            }
            .ques-box {
                // width: 25px;
                // height: 25px;
                display: inline-flex;
                align-items: center;

                border-radius: 4px;
                // position: absolute;
                // left: 0;
                // top:6px;
                background-color: #e6e9ec;
                color: $navy;
                font-size: 0.82rem;
                margin-right: 15px;
                padding: 0 8px;
                flex-basis: 20%;
            }
            .error-msg {
                flex-basis: 100%;
                margin-left: 23%;
            }
        }
        .proceed2 {
            min-width: 11.25rem;
        }
      &.profile-secure-ques {
          .form-group  {
              align-items: flex-start;
            .ques-box {
                margin-right: 10px;
                padding: 0 0px 0 5px;
                flex-basis: 20%;
                margin-top: 1rem;
            }
            .ques-right {
                flex-basis: 76%;
                .error-msg {
                    margin-left: 0;
                }
            }
            @media (max-width: 441px) {
                margin-bottom: 10px;
                .ques-box {
                    flex-basis: 27%;
                }
                .ques-right {
                    flex-basis: 100%;
                }
                .view-answer {
                    flex-basis: 100%;
                    margin-left: 0 !important;
                }
            }
          }
          .icon-edit {
            @media (max-width: 441px) {
                margin-top:0 !important;
                margin-bottom: 1rem !important;
            }
              &:hover {
                  color: $green-medium;
              }
          }

      }
      &.verification-secure-ques {
        padding: 2rem;
        .form-group {
            .ques-box {
                flex-basis: 18%;
            }

            .plain-select, .form-control {
                flex-basis: 77%;
            }
            .error-msg {
                margin-left: 21%;
                &.error-msg-verification {
                    margin-left: 0;
                }
            }
            @media (max-width: 441px) {
                .pre-qs {
                    flex-basis: 100%;
                    margin-top: 10px;
                }
                .error-msg {
                    margin-left: 0;
                }
            }
        }
      }
    }



    @media (max-width: 441px) {
        .secure-ques {
            .form-group {
                .ques-box {
                    flex-basis: 27%
                }
                .plain-select, .form-control {
                    flex-basis: 100%;
                }
                .error-msg {
                    margin-left: 0;
                }
            }
        }
    }


    @media (min-width: 768px) {
        .container {
           &.master-container {
            max-width: 720px;
           }
        }
    }

    .set-password-modal {
        width:270px;
            max-width: 500px;
            border: 1px solid $grey;
            .popover-body {
                .close-modal {
                    float: right;
                    cursor: pointer;
                    i {
                        font-size: 1.2rem;
                    }
                }
                h1 {
                    font-size: 1.2rem;
                    margin: 1.5rem 0;
                    text-align: center;
                }
        }
    }

    .view-user-popup  {
        .popover-body {
            min-width: 150px;
            padding: 15px;
            text-align: center;
            i {
                position: absolute;
                right: 0;
                top:5px;
                font-size: 1.125rem;
                margin: 0 0.3125rem;
                color: $green-medium;
                &:hover {
                    color: $navy;
                }
            }
        }
    }

    .subAgencyDropdown {
        .list-area {
            position: fixed;
        }
    }


    /* css for IE 11*/

_:-ms-fullscreen,
:root {
    .secure-ques {
        .form-group {
            .ques-box {
                flex-basis: 17%;
            }
            .error-msg {
                margin-left: 25%;
            }
             .plain-select, .form-control {
                flex-basis: 75%;
            }
        }
    }
    .form-signin {
        &.two-factor-authentication {
            width: 40rem;
        }
    }
}

.allowed-tooltip {
.tooltip-inner {
    max-width: 300px;
}
}

.add-sub-tooltip {
    .tooltip-inner {
        max-width: 240px;
    }
}

.change-answer-icon {
    color: $green-medium;
    &:hover {
        color: $navy !important;
    }
}



