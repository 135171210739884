@mixin button-hover-box-shadow() {
    box-shadow: 0 3px 5px -1px rgba($black, 0.2),
                0 6px 10px 0 rgba($black, 0.14),
                0 1px 18px 0 rgba($black, 0.12);
}

.mat-fab.mat-accent {
    background: rgba($green-medium, 1);
}

.mat-fab:not([class*=mat-elevation-z]) {
    box-shadow: none;

    &:hover {
        @include button-hover-box-shadow();
    }
}

.mat-flat-button {
    background: rgba($green-medium, 1);
    color: $white;
    border-radius: 4px;
    line-height: 36px;

    &:hover {
        @include button-hover-box-shadow();
    }
}

.mat-stroked-button {
    border-radius: 4px;
    line-height: 34px;
    background: 0 0;
}

.mat-btn {
    text-transform: uppercase;
}
